import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { config } from '@abyss/ui/tools/config';
import { event as newRelicEvent } from '@abyss/web/tools/event';
import { parseByTag, parseTagNames } from './parsers';

const useAction = actionFn => {
  const dispatch = useDispatch();
  return useCallback(
    (...args) => {
      return dispatch(actionFn(...args));
    },
    [dispatch]
  );
};

export const ACTION_TYPE = 'GET_DOCUMENTATION';

export const useDocumentation = options => {
  const result = useSelector(state => state[ACTION_TYPE]);
  const action = useAction(args => {
    const { service } = args;
    return dispatch => {
      dispatch({ type: `${ACTION_TYPE}_CALLED` });
      fetch(`${config('DOCUMENTATION_API_URL')}${service}.json`)
        .then(res => {
          newRelicEvent('NEWRELIC_API_TRANSACTION', {
            code: res.status,
            message: res.statusText,
            endpoint: config('DOCUMENTATION_API_URL'),
            userInfo: args.userInfo,
            method: 'GET',
          });
          return res.json();
        })
        .then(res => {
          const s = res;
          const pathsByTag = parseByTag(s, service);
          const tagNames = parseTagNames(pathsByTag);
          const data = {
            ...s,
            pathsByTag,
            tagNames,
            service,
          };

          dispatch({
            type: `${ACTION_TYPE}_COMPLETED`,
            payload: data,
          });
          if (options && options.onCompleted) {
            options.onCompleted(data);
          }
        })
        .catch(error => {
          dispatch({
            type: `${ACTION_TYPE}_ERROR`,
            payload: error,
          });
          if (options && options.onError) {
            options.onError(error);
          }
        });
    };
  });

  return [result, action];
};
