import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SelectInputMulti } from '@abyss/web/ui/SelectInputMulti';
import { TextInput } from '@abyss/web/ui/TextInput';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { TextInputArea } from '@abyss/web/ui/TextInputArea';
import { Label } from '@abyss/web/ui/Label';

import { styled } from '@abyss/web/tools/styled';
import { DateInput } from '@abyss/web/ui/DateInput';
import { useForm } from '@abyss/web/hooks/useForm';
import { useLagoon } from '@abyss/web/hooks/useLagoon';
import { RadioGroup } from '@abyss/web/ui/RadioGroup';
import { useCalendarApi } from '../../../../../hooks/useCalendarApi';
import { ErrorComponent } from '../../../../../common/Error/Error';

const Wrapper = styled('div', {
  '.abyss-descriptors-error-message': {
    fontSize: '13px ',
    fontWeight: '400',
  },
});

const FlexDisplay = styled('div', {
  display: 'flex',
  gap: '20px 4%',
  alignItems: 'baseline',
  flexWrap: 'wrap',

  '@screen < $sm': {
    gap: '20px 0',
  },
});

const FlexItem = styled('div', {
  flexBasis: '48%',
  '@screen < $sm': {
    flexBasis: '100%',
  },
});

const FlexItemApi = styled('div', {
  flexBasis: '100%',
  '@screen < $sm': {
    flexBasis: '100%',
  },
});

const TextAreaStyles = styled('div', {
  paddingTop: '20px',
  paddingBottom: '20px',
});

const RequiredLabel = styled('div', {
  fontSize: '15px',
  fontWeight: '600',
  marginBottom: '24px',
});

const FullNameText = styled('span', {
  color: '#424242',
  fontSize: '14px',
  lineHeight: '18px',
  fontWeight: 400,
  verticalAlign: '-webkit-baseline-middle',
});
export const FormFields = () => {
  const errorMessage = useLagoon('error-messages')();
  const userInfo = useSelector(state => state.GET_USER_INFO.data);
  const dsUserInfo = useSelector(state => state.GET_DS_USER_INFO.data);
  const holidayDateApi = useSelector(state => state.GET_CALENDAR_HOLIDAY.data);
  const form = useForm();
  const isFQHC = form.getValues('isFQHC');
  const [calendarDateApi, setCalendarDate] = useCalendarApi(
    state => state.GET_CALANDAR_TIMES
  );
  const [preferredConsultant, setPreferredConsultant] = useState('');
  const [swaggerOptions, setSwaggerOptions] = useState([]);
  const { nextWorkingDay, lastAllowedWorkingDay } = holidayDateApi || {};
  const messageExist = errorMessage.find(
    message =>
      message?.active && message?.key === 'FQHC_CONSULTANT_NOT_AVAILABLE'
  );
  const apisData = useLagoon('outlook-integrations-apis')();

  useEffect(() => {
    // if a new consultant is selected, clear the meeting times for the previous one
    form.setValue('preferredMeetingTime', '');
  }, [preferredConsultant, isFQHC]);

  useEffect(() => {
    // if a new date is selected, clear the FQHC question
    form.setValue('isFQHC', '');
  }, [calendarDateApi]);

  useEffect(() => {
    form.setValue('consultant', '');
  }, [isFQHC]);

  const validateDate = date => {
    const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;
    return dateRegex.test(date);
  };

  const callCalendarAPI = date => {
    if (validateDate(date)) {
      setCalendarDate({ date });
    }
  };

  const updateSwaggerOptions = options => {
    function getRaw(s) {
      return s.replace(/[&/\\#,+()$~%!.„'":*‚^_¤?<>|@ª{«»§}©®™ ]/g, '').trim();
    }
    const sortedOptions =
      options &&
      options.sort((a, b) =>
        getRaw(a.section.toLowerCase()) < getRaw(b.section.toLowerCase())
          ? -1
          : 1
      );
    setSwaggerOptions(sortedOptions);
  };

  const subCategories = (title, endpointList) => {
    const finalList = [];
    const list = endpointList.split(', ');
    list.forEach(item => {
      const obj = {
        value: `${item}`,
        label: `${item}`,
      };
      finalList.push(obj);
    });
    return finalList;
  };

  const obj = apisData?.map(item => {
    return {
      section: item.api,
      items: subCategories(item.api, item.endpoints),
    };
  });
  useEffect(() => {
    updateSwaggerOptions(obj);
  }, []);

  const isConnected =
    dsUserInfo?.status &&
    (dsUserInfo?.status === 'PENDING' || dsUserInfo?.status === 'ACTIVE');

  const consultantData = () => {
    const consultantsList = [];
    const availableConsultant = calendarDateApi?.data?.consultants || [];
    availableConsultant.forEach(element => {
      if (!element.fqhcFlag) {
        consultantsList.push({
          label: `${element?.firstName} ${element?.lastNameInitials}`,
          value: element.consultantId,
        });
      }
    });
    return consultantsList;
  };

  const fqhcName = () => {
    const name = calendarDateApi?.data?.consultants?.find(el => el.fqhcFlag);
    if (name) {
      const fqhcConsultantName = `${name?.firstName} ${name?.lastNameInitials}`;
      return fqhcConsultantName;
    }
    return (
      <div style={{ color: '#C40000', fontStyle: 'italic', marginTop: '10px' }}>
        {messageExist.message}
      </div>
    );
  };

  const consultantTime = () => {
    let consultantAvailableTimes;
    if (isFQHC === 'yes') {
      const fqhcConsultant = calendarDateApi?.data?.consultants?.find(
        el => el.fqhcFlag
      );
      consultantAvailableTimes = fqhcConsultant?.availableTimes;
    } else {
      const userSelectedConsultant = form.getValues('consultant');
      consultantAvailableTimes = calendarDateApi?.data?.consultants?.find(
        consultant => consultant?.consultantId === userSelectedConsultant
      )?.availableTimes;
    }
    const consultantsAvailableTimeOptions = consultantAvailableTimes?.map(
      time => ({
        label: time,
        value: time,
      })
    );

    return consultantsAvailableTimeOptions;
  };

  const unavailableCalendarDates = () => {
    if (nextWorkingDay) {
      return new Date(nextWorkingDay);
    }
    // if holidayDateApi is failing, use the next day as next available date
    return new Date();
  };

  const apiCalendarMaximumDate = () => {
    if (lastAllowedWorkingDay) {
      return new Date(lastAllowedWorkingDay);
    }
    // fallback scenario
    const maximumDate = new Date();
    maximumDate.setDate(maximumDate.getDate() + 60);

    return maximumDate;
  };

  const isHoliday = excludeDate => {
    return holidayDateApi?.holidays?.some(holiday => {
      const excludeDateConfigured = new Date(excludeDate).toLocaleDateString(
        'en-US',
        {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }
      );
      return holiday === excludeDateConfigured;
    });
  };

  const symbol = () => {
    return (
      <span style={{ color: '#C40000', fontSize: '27px', fontWeight: '200' }}>
        *
      </span>
    );
  };

  return (
    <Wrapper>
      <RequiredLabel>Required {symbol()}</RequiredLabel>
      <FlexDisplay>
        <If condition={!isConnected}>
          <FlexItem>
            <TextInput
              id="orgName"
              label="Organization Name"
              type="text"
              model="orgName"
              isClearable
              validators={{ required: true }}
              css={{
                'abyss-text-input-label': {
                  position: 'relative',
                  fontSize: '15px !important',
                },
              }}
            />
          </FlexItem>
        </If>
        <FlexItem id="fullName">
          <Label css={{ fontSize: '15px' }}>Full Name</Label>
          <FullNameText>{`${userInfo.firstName} ${userInfo.lastName ||
            userInfo.family_name}`}</FullNameText>
        </FlexItem>
        <If condition={!isConnected}>
          <FlexItem>
            <SelectInput
              id="orgType"
              label="Organization Type"
              model="orgType"
              placeholder="Choose an option"
              isClearable
              isSearchable
              options={[
                { label: 'Care Provider', value: 'Care Provider' },
                {
                  label: 'Revenue Cycle Management',
                  value: 'Revenue Cycle Management',
                },
                { label: 'Clearinghouse', value: 'Clearinghouse' },
                { label: 'Other', value: 'Other' },
              ]}
              validators={{ required: true }}
              css={{
                'abyss-select-input-label': {
                  position: 'relative',
                  fontSize: '15px',
                },
              }}
            />
          </FlexItem>
        </If>
        <If condition={!isConnected}>
          <FlexItem>
            <TextInput
              id="orgWebsite"
              label="Organization Website"
              type="text"
              model="orgWebsite"
              isClearable
              css={{
                'abyss-text-input-label': {
                  position: 'relative',
                  fontSize: '15px',
                },
              }}
            />
          </FlexItem>
        </If>
        <FlexItem>
          <TextInput
            id="email"
            label="Email"
            type="text"
            model="email"
            isClearable
            validators={{
              required: true,
              pattern: {
                // eslint-disable-next-line no-control-regex
                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: 'Invalid email format.',
              },
            }}
            css={{
              'abyss-text-input-label': {
                position: 'relative',
                fontSize: '15px',
              },
            }}
          />
        </FlexItem>
        <FlexItem>
          <TextInput
            id="phone"
            label="Phone Number"
            model="phone"
            isClearable
            mask="phone"
            css={{
              'abyss-text-input-label': {
                position: 'relative',
                fontSize: '15px',
              },
            }}
          />
        </FlexItem>
        <FlexItemApi>
          <SelectInputMulti
            id="selectedAPIs"
            label="API(s)"
            model="selectedAPIs"
            placeholder="Choose an option"
            isClearable
            isSearchable
            options={swaggerOptions}
            validators={{ required: true }}
            maxListHeight="290px"
            css={{
              'abyss-select-input-multi-label': {
                position: 'relative',
                fontSize: '15px',
              },
            }}
          />
        </FlexItemApi>

        <FlexItem>
          <DateInput
            id="preferredMeetingDate"
            onChange={callCalendarAPI}
            model="preferredMeetingDate"
            minimumDate={unavailableCalendarDates()}
            maximumDate={apiCalendarMaximumDate()}
            excludeDate={excludeDate => {
              return (
                excludeDate.getDay() === 0 ||
                excludeDate.getDay() === 6 ||
                isHoliday(excludeDate)
              );
            }}
            label="Preferred meeting date"
            validators={{ required: true }}
            subText={
              <span
                style={{
                  fontWeight: '1',
                  color: '#7d7f81',
                  'font-size': '13px',
                }}
              >
                Date Format: mm/dd/yyyy
              </span>
            }
            css={{
              'abyss-date-picker-input-label': {
                position: 'relative',
                fontSize: '15px',
              },
            }}
          />
          {calendarDateApi?.error ? (
            <ErrorComponent>{calendarDateApi?.data?.message}</ErrorComponent>
          ) : null}
        </FlexItem>

        <RadioGroup
          label="Are you designated as a FQHC, RHC, or Tribal group?"
          model="isFQHC"
          display="row"
          isDisabled={
            !form.getValues('preferredMeetingDate') ||
            !calendarDateApi?.data ||
            !Object.keys(calendarDateApi?.data)?.length
          }
          validators={{ required: true }}
          css={{
            'abyss-radio-group-label': {
              position: 'relative',
              fontSize: '15px',
            },
          }}
        >
          <RadioGroup.Radio label="Yes" value="yes" />
          <RadioGroup.Radio label="No" value="no" />
        </RadioGroup>
        <If condition={isFQHC === 'yes'}>
          <FlexItem id="FQHCconsultantName">
            <Label css={{ fontSize: '15px' }}>
              Preferred consultant {symbol()}
            </Label>
            <FullNameText>{fqhcName()} </FullNameText>
          </FlexItem>
        </If>
        <If condition={isFQHC === '' || isFQHC === 'no'}>
          <FlexItem>
            <SelectInput
              id="consultant"
              label="Preferred consultant"
              subText={
                <span
                  style={{
                    fontWeight: '1',
                    color: '#7d7f81',
                    fontSize: '13px',
                  }}
                >
                  If there are no consultants available or you don't see your
                  preferred consultant's name, please select a different date.
                </span>
              }
              model="consultant"
              isDisabled={
                !form.getValues('preferredMeetingDate') ||
                !form.getValues('isFQHC') ||
                !calendarDateApi?.data ||
                !Object.keys(calendarDateApi?.data)?.length
              }
              validators={{ required: true }}
              onChange={setPreferredConsultant}
              options={consultantData()}
              placeholder="Select a consultant"
              css={{
                'abyss-select-input-label': {
                  position: 'relative',
                  fontSize: '15px',
                },
              }}
            />
          </FlexItem>
        </If>
        <FlexItem>
          <SelectInput
            id="preferredMeetingTime"
            label="Preferred meeting time of day"
            model="preferredMeetingTime"
            isDisabled={
              (isFQHC === 'no' && !form.getValues('consultant')) ||
              !calendarDateApi?.data ||
              !Object.keys(calendarDateApi?.data)?.length ||
              !isFQHC
            }
            validators={{ required: true }}
            options={consultantTime()}
            placeholder="Select a meeting time"
            subText={
              <span
                style={{
                  fontWeight: '1',
                  color: '#7d7f81',
                  'font-size': '13px',
                }}
              >
                Eastern Time Zone (ET)
              </span>
            }
            css={{
              'abyss-select-input-label': {
                position: 'relative',
                fontSize: '15px',
              },
              'abyss-descriptors-sub-text': {
                fontSize: '10px',
              },
            }}
          />
        </FlexItem>
      </FlexDisplay>
      <TextAreaStyles>
        <TextInputArea
          id="businessValue"
          maxLength={500}
          label="Business Value"
          model="businessValue"
          placeholder="Please explain your intended use for this API."
          validators={{ required: true }}
          className="abyss-label-font"
          css={{
            'abyss-text-input-area-label': {
              position: 'relative',
              fontSize: '15px',
            },
          }}
        />
      </TextAreaStyles>
    </Wrapper>
  );
};

FormFields.propTypes = {};
