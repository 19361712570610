import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { config } from '@abyss/ui/tools/config';
// import { event as newRelicEvent } from '@abyss/web/tools/event';

const useAction = actionFn => {
  const dispatch = useDispatch();
  return useCallback(
    (...args) => {
      return dispatch(actionFn(...args));
    },
    [dispatch]
  );
};

export const ACTION_TYPE = 'DELETE_DRAFT_FILES';

export const resetDeleteFiles = () => {
  const result = useSelector(state => state[ACTION_TYPE]);
  const action = useAction(() => {
    return dispatch => {
      dispatch({ type: `${ACTION_TYPE}_RESET` });
    };
  });
  return [result, action];
};

export const useDeleteFile = () => {
  const result = useSelector(state => state[ACTION_TYPE]);

  const action = useAction(args => {
    const { fileKeyList } = args;
    return dispatch => {
      dispatch({ type: `${ACTION_TYPE}_CALLED` });

      const request = {
        Objects: fileKeyList,
        Quiet: false,
      };

      fetch(config('S3_DELETE_FILE_URL'), {
        credentials: 'include',
        method: 'delete',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(request),
      })
        .then(res => res.json())
        .then(res => {
          dispatch({
            type: `${ACTION_TYPE}_COMPLETED`,
            payload: res,
          });
        })
        .catch(error => {
          dispatch({
            type: `${ACTION_TYPE}_ERROR`,
            payload: error,
          });
        });
    };
  });

  return [result, action];
};
