import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { config } from '@abyss/ui/tools/config';
import { event as newRelicEvent } from '@abyss/web/tools/event';

const useAction = actionFn => {
  const dispatch = useDispatch();
  return useCallback(
    (...args) => {
      return dispatch(actionFn(...args));
    },
    [dispatch]
  );
};

export const ACTION_TYPE = 'POST_SEND_COMMUNICATION';

export const resetSendCommunicationApi = () => {
  const result = useSelector(state => state[ACTION_TYPE]);
  const action = useAction(() => {
    return dispatch => {
      dispatch({ type: `${ACTION_TYPE}_RESET` });
    };
  });
  return [result, action];
};

const convertToJson = (res, args) => {
  newRelicEvent('NEWRELIC_API_TRANSACTION', {
    code: res.status,
    message: res.statusText,
    endpoint: config('COMMUNICATION_URL'),
    userInfo: args.userInfo,
    method: 'POST',
  });
  return new Promise(resolve =>
    res.json().then(response =>
      resolve({
        status: res.status,
        response,
      })
    )
  );
};

const getAction = () => {
  const action = useAction(args => {
    const { variables = {} } = args;
    const { request, files } = variables;
    return dispatch => {
      dispatch({ type: `${ACTION_TYPE}_RESET` });
      dispatch({ type: `${ACTION_TYPE}_CALLED` });

      const formData = new FormData();
      formData.append('communicationsCornerRequest', JSON.stringify(request));
      if (files && files.length > 0) {
        files.forEach((file, index) => {
          formData.append(`file${index + 1}`, file);
        });
      }

      fetch(
        `${config('APIM_STARGATE_URL')}${config(
          'COMMUNICATION_URL'
        )}/attachment/upload/v1.0`,
        {
          credentials: 'include',
          method: 'post',
          body: formData,
        }
      )
        .then(res => convertToJson(res, args))
        .then(res => {
          if (res.status !== 200) {
            if (res.status === 500) {
              throw new Error(
                'Your request was unsuccessful, please try again.'
              );
            } else {
              dispatch({
                type: `${ACTION_TYPE}_ERROR`,
                payload: res.response,
              });
            }
          } else {
            dispatch({
              type: `${ACTION_TYPE}_COMPLETED`,
              payload: res.response,
            });
          }
        })
        .catch(error => {
          dispatch({
            type: `${ACTION_TYPE}_ERROR`,
            payload: error,
          });
        });
    };
  });
  return action;
};
export const useSendCommunicationApi = () => {
  const result = useSelector(state => state[ACTION_TYPE]);
  const action = getAction();

  return [result, action];
};
