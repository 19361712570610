import React from 'react';
import { event } from '@abyss/web/tools/event';
import { useRouter } from '@abyss/ui/router/hooks/useRouter';
import { styled } from '@abyss/web/tools/styled';
import LaunchIcon from '../../../assets/launch.png';
import { constants } from '../../../common/Utils/Constants';

const FooterBar = styled('div', {
  backgroundColor: '#002677',
  minHeight: '190px',
  width: '100%',
  padding: '16px',
});

const FooterLayout = styled('div', {
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: '1200px',
});

const PrimaryContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  minHeight: '95px',
  padding: '10px 0 30px 0',

  '@screen >= $xs': {
    flexDirection: 'column',
    alignItems: 'baseline',
  },

  '@screen >= $xl': {
    flexDirection: 'row',
    alignItems: 'center',
    gap: '315px',
  },
});

const SecondaryContainer = styled('div', {
  alignItems: 'center',
  height: '95px',
  justifyContent: 'flex-start',

  '@screen >= $xs': {
    flexDirection: 'column',
    alignItems: 'baseline',
    display: 'none',
    gap: '15px',
  },

  '@screen >= $sm': {
    flexDirection: 'row',
    alignItems: 'center',
    display: 'flex',
    gap: '15px',
  },
});

const SecondaryContainerMobileView = styled('div', {
  alignItems: 'center',
  minHeight: '95px',
  marginBottom: '18px',
  justifyContent: 'center',

  '@screen >= $xs': {
    flexDirection: 'column',
    alignItems: 'baseline',
    display: 'block',
    gap: '15px',
  },

  '@screen >= $sm': {
    display: 'none',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '15px',
  },
});

const LaunchIconWrapper = styled('img', {
  maxWidth: '24px',
  height: '24px',
  position: 'absolute',
  top: '-3px',
  right: '-30px',
});

const IconSpan = styled('span', {
  position: 'relative',
});

const Divider = styled('div', {
  height: '1px',
  backgroundColor: 'white',

  '@screen >= $xs': {
    display: 'none',
  },

  '@screen >= $sm': {
    display: 'flex',
  },
});

const DividerMobile = styled('div', {
  height: '1px',
  backgroundColor: 'white',
  marginTop: '42px',
  marginBottom: '32px',
});

const Item = styled('p', {
  flexBasis: '33.33%',
  height: '18px',
  color: constants.FONT_COLOR_WHITE,
  fontSize: '15px',
  fontWeight: 'bold',
  letterSpacing: '0px',
  lineHeight: '29px',
  marginBottom: '5px',

  '&:hover': {
    textDecoration: 'none',
    color: constants.FONT_COLOR_WHITE,
    cursor: 'pointer',
  },
});

const SecItem = styled('p', {
  color: constants.FONT_COLOR_WHITE,
  fontSize: '13px',
  fontWeight: 400,
  letterSpacing: '0px',
  lineHeight: '18px',
  marginBottom: 0,
});

const SecItemMobileView = styled('p', {
  color: constants.FONT_COLOR_WHITE,
  fontSize: '14px',
  fontWeight: 400,
  letterSpacing: '0px',
  lineHeight: '18px',
});

const RightItems = styled('p', {
  color: constants.FONT_COLOR_WHITE,
  fontSize: '13px',
  fontWeight: 400,
  letterSpacing: '0px',
  lineHeight: '18px',
  marginBottom: '0px',
  textAlign: 'inherit',

  '@screen >= $xs': {
    marginLeft: 0,
    gap: '15px',
  },

  '@screen >= $sm': {
    marginLeft: 'auto',
    gap: '15px',
  },
});

const Anchor = styled('a', {
  color: constants.FONT_COLOR_WHITE,

  '&:hover': {
    textDecoration: 'none',
    color: constants.FONT_COLOR_WHITE,
  },
});

export const Footer = () => {
  const router = useRouter();
  const year = new Date().getFullYear();
  return (
    <FooterBar>
      <FooterLayout>
        <PrimaryContainer>
          <Item>
            <Anchor
              href="/site-map"
              id="siteMap"
              onClick={e => {
                e.preventDefault();
                router.push('/site-map');
                const interactionName = 'footer';
                const interactionValue = 'site map';
                const interactionContext = 'footer link click';
                event('INTERACTION_EVENT', {
                  interactionName,
                  interactionValue,
                  interactionContext,
                });
              }}
            >
              Site Map
            </Anchor>
          </Item>
          <Item>
            <Anchor
              href="https://www.uhc.com/about-us"
              target="_blank"
              onClick={() => {
                const exitLink = 'About United Healthcare';
                const exitURL = 'https://www.uhc.com/about-us';
                event('EXIT_EVENT', {
                  event,
                  exitLink,
                  exitURL,
                });
              }}
            >
              About United Healthcare
              <IconSpan>
                <LaunchIconWrapper src={LaunchIcon} />
              </IconSpan>
            </Anchor>
          </Item>
          <Item>
            <Anchor
              href="https://www.uhc.com/legal/nondiscrimination-and-language-assistance-notices"
              target="_blank"
              onClick={() => {
                const exitLink = 'Language Assistance';
                const exitURL =
                  'https://www.uhc.com/legal/nondiscrimination-and-language-assistance-notices';
                event('EXIT_EVENT', {
                  event,
                  exitLink,
                  exitURL,
                });
              }}
            >
              Language Assistance
              <IconSpan>
                <LaunchIconWrapper src={LaunchIcon} />
              </IconSpan>
            </Anchor>
          </Item>
        </PrimaryContainer>
        <Divider />
        <SecondaryContainer>
          <SecItem>
            <span>© {year} </span> United Healthcare Services, Inc. | All Rights
            Reserved
          </SecItem>
          <RightItems>
            <Anchor href="https://www.uhc.com/privacy" target="_blank">
              Privacy | &nbsp;
            </Anchor>
            <Anchor
              href="https://www.uhcprovider.com/en/terms-of-use.html"
              target="_blank"
            >
              Terms of Use | &nbsp;
            </Anchor>
            <Anchor
              href="https://www.uhc.com/legal/accessibility"
              target="_blank"
            >
              Accessibility
            </Anchor>
          </RightItems>
        </SecondaryContainer>
        <SecondaryContainerMobileView>
          <RightItems>
            <Anchor href="https://www.uhc.com/privacy" target="_blank">
              Privacy | &nbsp;
            </Anchor>
            <Anchor
              href="https://www.uhcprovider.com/en/terms-of-use.html"
              target="_blank"
            >
              Terms of Use | &nbsp;
            </Anchor>
            <Anchor
              href="https://www.uhc.com/legal/accessibility"
              target="_blank"
            >
              Accessibility
            </Anchor>
          </RightItems>
          <DividerMobile />
          <SecItemMobileView>
            <span>© </span>
            {year} United Healthcare Services, Inc.
          </SecItemMobileView>
          <SecItem>All Rights Reserved</SecItem>
        </SecondaryContainerMobileView>
      </FooterLayout>
    </FooterBar>
  );
};
