import React from 'react';
import PropTypes from 'prop-types';
import { CodeHighlighter } from '@abyss/web/ui/CodeHighlighter';
import { styled } from '@abyss/web/tools/styled';

const BodyContainer = styled('div', {
  marginTop: 24,
});

export const Body = ({ body }) => {
  const formattedCode = JSON.stringify(body, null, 2);
  return (
    <BodyContainer>
      <CodeHighlighter language="js" code={formattedCode} maxHeight="450px" />
    </BodyContainer>
  );
};

Body.propTypes = {
  body: PropTypes.shape({}),
};

Body.defaultProps = {
  body: null,
};
