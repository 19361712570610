import React from 'react';
import { Modal } from '@abyss/web/ui/Modal';
import PropTypes from 'prop-types';
import { Button } from '@abyss/web/ui/Button';
import { styled } from '@abyss/web/tools/styled';
import { useGetCommunicationAPI } from 'src/hooks/useGetCommunicationAPI';

const SubmitButton = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: '15px',
});

export const ConfirmationModal = ({ isOpen, isClosed, title }) => {
  const [, getCommunicationAPI] = useGetCommunicationAPI();

  const modalClose = () => {
    getCommunicationAPI({ allMessages: true });
    isClosed(false);
  };

  return (
    <Modal
      title={title}
      isOpen={isOpen}
      onClose={() => modalClose()}
      css={{
        'abyss-modal-header-container': {
          color: '#002677',
          fontSize: '28.83px',
        },
        'abyss-modal-content-title': { 'font-size': '26px !important' },
      }}
    >
      <Modal.Section>
        <SubmitButton>
          <Button
            id="submitButton"
            fontSize="15px"
            size="$md"
            onClick={() => modalClose()}
          >
            OK
          </Button>
        </SubmitButton>
      </Modal.Section>
    </Modal>
  );
};
ConfirmationModal.propTypes = {
  isClosed: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};
