import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PageLayout } from 'src/common/PageLayout';
import { Button } from '@abyss/web/ui/Button';
import { event } from '@abyss/web/tools/event';
import { Link } from '@abyss/web/ui/Link';
import { StyledLink } from 'src/common/Link';
import { Breadcrumbs } from 'src/app/App/Breadcrumbs/Breadcrumbs';
import { Testimonials } from 'src/common/Testimonials/Testimonials';
import { useRouter } from '@abyss/ui/router/hooks/useRouter';
import { testimonialMock } from 'src/app/App/__mock_data__/testimonialMock';
import { APISlider } from 'src/common/APISlider/APISlider';
import { relatedAPIsMock } from 'src/app/App/__mock_data__/priorauth_relatedAPI';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { styled } from '@abyss/web/tools/styled';
import { IconBrand } from '@abyss/web/ui/IconBrand';
import { SubscribeModal } from '../SubscribeModal';
import { SubscribeToAPIModal } from '../../SubscribeToAPIModal';
import { useDSUserInfo } from '../../../../../hooks/useDSUserInfo';
import { constants } from '../../../../../common/Utils/Constants';

const Wrapper = styled('div', {
  '@screen >= $xs': {
    padding: '16px',
  },

  '@screen >= $xl': {
    padding: '32px 0 0 0',
  },
});

const StyledFlex = styled('div', {
  display: 'flex',
  flexBasis: '75%',
});

const Container = styled('div', {
  flexBasis: '100%',
});

const PageHeadingContainer = styled('div', {
  display: 'flex',
  marginTop: '8px',
  marginBottom: '32px',
  flexBasis: '50%',
});

const Icon = styled('div', {
  height: '49.5px',
  width: '60px',

  '@screen >= $xs': {
    display: 'none',
  },

  '@screen >= $sm': {
    display: 'block',
  },
});

const PageHeading = styled('h1', {
  color: 'rgb(0, 38, 119)',
  fontSize: '36px',
  fontWeight: '600',
  minHeight: '40px',
  lineHeight: '40px',
  marginTop: '8px',
});

const PageSubHeading = styled('h2', {
  color: 'rgb(0, 38, 119)',
  fontSize: '28px !important',
  fontWeight: '600',
  minHeight: '32px',
  lineHeight: '32px',
  marginBottom: '16px',
});

const PageSecondaySubHeading = styled('h4', {
  color: `${constants.FONT_COLOR_GREY} !important`,
  fontSize: '23px !important',
  fontWeight: 'bold',
  lineHeight: '24px',
  marginBottom: '16px',
  marginTop: '16px',
});

const PageLinkContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  margin: '0',
  marginTop: '32px',
  marginBottom: '0px',
});

const PageDesc = styled('div', {
  color: `${constants.FONT_COLOR_GREY} !important`,
  fontSize: '16px',
  fontWeight: 400,
  letterSpacing: '0px',
  lineHeight: '24px',
  marginBottom: '16px',
  whiteSpace: 'pre-line',
});

const PageContent = styled('div', {
  color: `${constants.FONT_COLOR_GREY} !important`,
  fontSize: '16px',
  fontWeight: 400,
  minHeight: '275px',
  letterSpacing: '0px',
  lineHeight: '24px',
  marginBottom: '48px',
});

const UL = styled('ul', {
  margin: '32px 0px 32px 48px',
  listStyle: 'disc',
});

const LI = styled('li', {
  margin: '8px',
  fontWeight: 'normal',
});

const AnchorBox = styled('div', {
  height: '20px',
  display: 'flex',
  alignContent: 'center',
  marginBottom: '16px',
  marginTop: '8px',
  marginLeft: '8px',

  button: {
    marginRight: '12px',
  },
});

const ButtonMargin = styled('div', {
  margin: '24px 0',

  '@screen >= $xs': {
    button: {
      width: '100%',
    },
  },

  '@screen >= $sm': {
    button: {
      width: '339px',
    },
  },

  '@screen >= $xl': {
    textAlign: 'right',
  },
});

const Flexed = styled('div', {
  '@screen >= $xs': {
    display: 'block',
  },

  '@screen >= $xl': {
    display: 'flex',
  },
});

const ContainerHalf = styled('div', {
  flexBasis: '50%',
});

const LinkMargin = styled('div', {
  alignSelf: 'center',
});

export const PriorAuthAPI = () => {
  const router = useRouter();
  const [isSubscribeModalOpen, setIsSubscribeModalOpen] = useState(false);
  const [showSubscribeAPIModal, setShowSubscribeAPIModal] = useState(false);
  const userInfo = useSelector(state => state.GET_USER_INFO.data);
  const dsUserInfo = useSelector(state => state.GET_DS_USER_INFO);
  const [, getDSUserInfo] = useDSUserInfo();

  const interactionContextPageCTALinkClicks = 'page cta link clicks';
  const interactionName = 'Prior Auth';

  useEffect(() => {
    if (userInfo.uuid && !dsUserInfo.data.status) {
      getDSUserInfo({ userInfo });
    }
  }, [userInfo]);

  const subscribeToAPI = () => {
    const interactionValue = 'request an api subscription';
    event('INTERACTION_EVENT', {
      interactionName,
      interactionValue,
      interactionContext: interactionContextPageCTALinkClicks,
    });
  };

  const subscribeClicked = () => {
    const jobName = constants.SUB_TO_API_JOB_NAME;

    subscribeToAPI();
    event('SUBSCRIBE_EVENT', {
      event,
      jobName,
    });
    if (userInfo.firstName === '' || userInfo.error) {
      setIsSubscribeModalOpen(true);
    } else {
      setShowSubscribeAPIModal(true);
    }
  };

  useEffect(() => {
    event('API_DETAILS_PRIORAUTHAPI_PAGE_LOAD', {
      dsUserInfo,
      userInfo,
    });
  }, []);

  const accessSandbox = () => {
    const interactionValue = 'access sandbox';
    event('INTERACTION_EVENT', {
      interactionName,
      interactionValue,
      interactionContext: interactionContextPageCTALinkClicks,
    });
    router.push('/documentation/priorauth');
  };

  const apiDocumentation = () => {
    const interactionValue = 'api technical documentation';
    event('INTERACTION_EVENT', {
      interactionName,
      interactionValue,
      interactionContext: interactionName,
    });
    router.push('/documentation/priorauth');
  };

  return (
    <PageLayout>
      <SubscribeModal
        setIsSubscribeModalOpen={setIsSubscribeModalOpen}
        isSubscribeModalOpen={isSubscribeModalOpen}
      />
      <SubscribeToAPIModal
        showModal={showSubscribeAPIModal}
        modalOnClose={setShowSubscribeAPIModal}
      />
      <Wrapper>
        <StyledFlex>
          <Container>
            <Breadcrumbs />
            <Flexed>
              <PageHeadingContainer>
                <Icon>
                  <IconBrand icon="clipboard_checked" size={48} />
                </Icon>
                <PageHeading>Prior Auth API</PageHeading>
              </PageHeadingContainer>
              <ContainerHalf>
                <ButtonMargin>
                  <Button
                    variant="solid"
                    onClick={apiDocumentation}
                    css={{
                      backgroundColor: '#002677',
                      fontSize: '15px',
                      height: '42px',

                      '@screen >= $xs': {
                        width: '100%',
                      },

                      '@media (min-width: 576px)': {
                        width: '339px',
                      },
                    }}
                  >
                    API Technical Documentation
                  </Button>
                </ButtonMargin>
              </ContainerHalf>
            </Flexed>
            <PageSubHeading>Overview</PageSubHeading>
            <PageDesc>
              The Prior Auth API consists of three components: Case Detail, Case
              Search and Case Attachments.{'\n'} {'\n'}As of August 2022, the
              Prior Auth APIs are only available to existing clients in
              production and the APIs have been placed in maintenance mode.
              Please contact us at{' '}
              <Link href="mailto:APIconsultant@uhc.com">
                APIconsultant@uhc.com
              </Link>{' '}
              for any questions.
              {'\n'} {'\n'}Case Detail supports the querying of a specific case
              (by Service Reference Number) to return the details of that case
              from our Integrated Clinical User Experience (ICUE) application to
              the user. If the User has preceded this call with the Cases Search
              call to return a list of cases, and then decides to select one of
              the cases in the list, the call to the case detail read will
              return all the case details for the case selected. Optionally,
              when the User enters a specific Service Reference Number (SRN) –
              searchId – in the case search request parameters, the call to Case
              Detail Read will return all the case details for the case with
              that SRN.{'\n'} {'\n'}Case Search supports the querying of our
              Integrated Clinical User Experience (ICUE) application to find and
              return a list of possible duplicate cases (summary, not detail)
              based on only the Member and Submitting Provider. It can also
              support the manual search by a User to provide a summary list of
              all cases based on just the Submitting Provider, or the Submitting
              Provider and the Member.{'\n'}
              {'\n'}Case Attachments supports the attachment of files, as
              additional documentation, to a Health Service Case (HSC) in the
              Integrated Clinical User Experience (ICUE) application by a User.
              This service can be used during case create or case update.{'\n'}
              {'\n'}Our API integrates with electronic medical records (EMRs),
              electronic health records (EHRs), third party billing companies
              and has the capability to be used for application or product
              development.
            </PageDesc>
            <PageLinkContainer>
              <AnchorBox>
                <LinkMargin>
                  <StyledLink
                    id="SubscribeToAPI"
                    onClick={subscribeClicked}
                    after={<IconMaterial icon="arrow_forward" size="20px" />}
                    fontWeight="$bold"
                  >
                    {constants.SUBSCRIBE_TO_API}
                  </StyledLink>
                </LinkMargin>
              </AnchorBox>
              <AnchorBox>
                <LinkMargin>
                  <StyledLink
                    onClick={accessSandbox}
                    after={<IconMaterial icon="arrow_forward" size="20px" />}
                    fontWeight="$bold"
                  >
                    Access Sandbox
                  </StyledLink>
                </LinkMargin>
              </AnchorBox>
            </PageLinkContainer>
            <PageSecondaySubHeading>
              About Prior Auth API
            </PageSecondaySubHeading>
            <PageContent>
              This is a Representational State Transfer (REST) API that
              includes:
              <UL>
                <LI>
                  Metadata, identifying the caller and enabling statistics for
                  frequency of calls.
                </LI>
                <LI>
                  Identifier, is UpdateIndicator, identifying this call as an
                  update to an existing case.
                </LI>
                <LI> Member Identifying details.</LI>
                <LI> Provider Identifying details.</LI>
                <LI>
                  Case detail information for service setting, diagnosis,
                  procedure, case status, outcome decision, facility, and
                  contacts.
                </LI>
                <LI>Primary Service Reference Number of the Case.</LI>
                <LI>
                  Attachments: file name, file content type, upload sequence
                  number, and file attachment in bytes (text, images, etc.).
                </LI>
                <LI>Unique Member ID according to the source application.</LI>
                <LI>Requested Search ID (SRN).</LI>
                <LI>
                  Filters for selecting only those cases that fall within a date
                  range, or have a specific case status, or service setting -- 1
                  (IP), 2 (OP), 3 (OP-F).
                </LI>
                <LI>
                  Service Response with success/failure codes, primary service
                  reference number, and disclaimer text. For failures,
                  additional error information.
                </LI>
              </UL>
              This information will allow your organization to decrease manual
              processes, limit out sourcing demand, and improve provider payment
              timeframes.
            </PageContent>
          </Container>
        </StyledFlex>
        <Testimonials initialData={testimonialMock} />
        <APISlider initialData={relatedAPIsMock} />
      </Wrapper>
    </PageLayout>
  );
};
