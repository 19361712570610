import { config } from '@abyss/ui/tools/config';
import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { event as newRelicEvent } from '@abyss/web/tools/event';

const useAction = actionFn => {
  const dispatch = useDispatch();
  return useCallback(
    (...args) => {
      return dispatch(actionFn(...args));
    },
    [dispatch]
  );
};

export const ACTION_TYPE = 'GET_FILE_DOWNLOAD';

export const resetDownloadFileAPI = () => {
  const result = useSelector(state => state[ACTION_TYPE]);
  const action = useAction(() => {
    return dispatch => {
      dispatch({ type: `${ACTION_TYPE}_RESET` });
    };
  });
  return [result, action];
};

export const useDownloadFile = () => {
  const result = useSelector(state => state[ACTION_TYPE]);

  const action = useAction(args => {
    const { fileKey } = args;
    return dispatch => {
      dispatch({ type: `${ACTION_TYPE}_CALLED` });
      let addHeaders = {};
      if (fileKey) {
        addHeaders = {
          filekey: fileKey,
        };
      }
      fetch(`${config('S3_DOWNLOAD_FILE_URL')}`, {
        credentials: 'include',
        headers: { ...addHeaders },
        method: 'get',
      })
        .then(res => res.json())
        .then(res => {
          const { fileContent } = res;
          const { filename } = res.metadata;
          const response = {
            filename,
            fileContent,
            fileKey,
          };

          dispatch({
            type: `${ACTION_TYPE}_COMPLETED`,
            payload: response,
          });
        })
        .catch(error => {
          dispatch({
            type: `${ACTION_TYPE}_ERROR`,
            payload: error,
          });
        });
    };
  });
  return [result, action];
};
