import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { config } from '@abyss/ui/tools/config';
import { event as newRelicEvent } from '@abyss/web/tools/event';

const useAction = actionFn => {
  const dispatch = useDispatch();
  return useCallback(
    (...args) => {
      return dispatch(actionFn(...args));
    },
    [dispatch]
  );
};

export const ACTION_TYPE = 'POST_TIN_MANAGEMENT_API';

export const resetPostTinManagement = () => {
  const result = useSelector(state => state[ACTION_TYPE]);
  const action = useAction(() => {
    return dispatch => {
      dispatch({ type: `${ACTION_TYPE}_RESET` });
    };
  });
  return [result, action];
};

export const usePostTinApi = () => {
  const result = useSelector(state => state[ACTION_TYPE]);

  const action = useAction(args => {
    const { tins = [] } = args;
    return dispatch => {
      dispatch({ type: `${ACTION_TYPE}_RESET` });
      dispatch({ type: `${ACTION_TYPE}_CALLED` });
      fetch(`${config('APIM_STARGATE_URL')}${config('TIN_MANAGEMENT_API')}`, {
        withCredentials: 'include',
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ tins }),
      })
        .then(res => {
          newRelicEvent('NEWRELIC_API_TRANSACTION', {
            code: res.status,
            message: res.statusText,
            endpoint: config('TIN_MANAGEMENT_API'),
            userInfo: args.userInfo,
            method: 'POST',
          });
          return res.status;
        })
        .then(res => {
          const statusJson = { status: res };
          if (res !== 200) {
            dispatch({
              type: `${ACTION_TYPE}_ERROR`,
              payload: statusJson,
            });
          } else {
            dispatch({
              type: `${ACTION_TYPE}_COMPLETED`,
              payload: statusJson,
            });
          }
        })
        .catch(error => {
          dispatch({
            type: `${ACTION_TYPE}_ERROR`,
            payload: error,
          });
        });
    };
  });

  return [result, action];
};
