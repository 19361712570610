import { config } from '@abyss/ui/tools/config';
import { event } from '@abyss/web/tools/event';
import { newRelicAnalytics } from '@abyss/web/tools/event/listeners/newRelicAnalytics';
import { adobeAnalytics } from '@abyss/web/tools/event/listeners/adobeAnalytics';
import { adobeTags } from './tags/adobe';
import { newRelicTags } from './tags/newRelic';

const maskAdobeClickTracking = () => {
  event.waitFor(
    () => {
      return window.s && window.s.ActivityMap;
    },
    () => {
      window.s.ActivityMap.link = () => null;
    }
  );
};

export const ADOBE_ANALYTICS_ID = 'adobe-analytics-apimarketplace';

export const registerAnalytics = data => {
  maskAdobeClickTracking();

  const adobeData = adobeAnalytics({
    id: 'adobe-analytics-apimarketplace',
    enabled: config('ANALYTICS_TRACKING'),
    logging: config('ANALYTICS_LOGGING'),
    events: adobeTags,
    metadata: data,
  });

  event.listener(adobeData);

  event.listener(
    newRelicAnalytics({
      id: 'newRelic-apimarketplace',
      enabled: config('ANALYTICS_TRACKING'),
      logging: config('ANALYTICS_LOGGING'),
      events: newRelicTags,
      metadata: data,
    })
  );
};
