import React, { useEffect, useMemo, useState } from 'react';
import { storage } from '@abyss/web/tools/storage';
import { config } from '@abyss/ui/tools/config';
import { styled } from '@abyss/web/tools/styled';
import { useUserInfo } from 'src/hooks/useUserInfo';
import { Button } from '@abyss/web/ui/Button';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { DataTable } from '@abyss/web/ui/DataTable';
import { LoadingOverlay } from '@abyss/web/ui/LoadingOverlay';
import { Tabs } from '@abyss/web/ui/Tabs';
import { useDSUserInfo } from 'src/hooks/useDSUserInfo';
import { useLagoon } from '@abyss/web/hooks/useLagoon';
import { Alert } from '@abyss/web/ui/Alert';
import { useRouter } from '@abyss/ui/router/hooks/useRouter';
import { resetDeleteFiles, useDeleteFile } from 'src/hooks/useDeleteFile';
import { event } from '@abyss/web/tools/event';
import { PageLayout } from '../../../../../common/PageLayout';
import { Breadcrumbs } from '../../../Breadcrumbs/Breadcrumbs';
import { CreateCommunicationModal } from './CreateCommunicationModal/CreateCommunicationModal';
import { DeleteSelectedDraftsModal } from './DeleteSelectedDraftsModal/DeleteSelectedDraftsModal';
import { ViewEmailModal } from './ViewEmailModal/ViewEmailModal';
import { useGetCommunicationAPI } from '../../../../../hooks/useGetCommunicationAPI';
import {
  resetDraftApi,
  useGetDraftApi,
} from '../../../../../hooks/useGetDraftApi';
import { useGetSentCommunicationApi } from '../../../../../hooks/useGetSentCommunicationApi';
import { ConfirmationModal } from './ConfirmationModal';
import {
  resetDeleteCommunicationAPI,
  useDeleteCommunicationAPI,
} from '../../../../../hooks/useDeleteCommunicationAPI';
import { checkInternalRole } from '../../../../../common/Utils/utils';

import { CreateCommunicationPageDesc } from './CreateCommunicationPageDescription';
import { constants } from '../../../../../common/Utils/Constants';

const PageHeading = styled('h1', {
  color: '#002677',
  fontSize: '41px !important',
  fontWeight: '600',
  lineHeight: '52px',
  marginBottom: '32px',
  '@screen < $sm': {
    fontSize: '25px',
  },
});

const ButtonStyle = styled('div', {
  marginBottom: '35px',
  marginTop: '35px',
});

const AlertStyle = styled('div', {
  marginBottom: '35px',
});

export const CreateCommunication = () => {
  const [userDSInfo, getDSUserInfo] = useDSUserInfo();
  const userDSData = userDSInfo?.data;
  const callDsUserInfo = !userDSData?.uuid && !userDSData?.status;
  const [dsUserInfoCalled, setDsUserInfoCalled] = useState(false);
  const [userInfo, getUserInfo] = useUserInfo();
  const userInfoData = userInfo.data;
  const [communicationAPI, getCommunicationAPI] = useGetCommunicationAPI();
  const [draftApi, getDraftCommunication] = useGetDraftApi();
  const [
    sentCommunication,
    getSentCommunication,
  ] = useGetSentCommunicationApi();
  const { draftMessages = [], sentMessages = [] } = communicationAPI.data || {};
  const [showCreateCommModal, setShowCreateCommModal] = useState(false);
  const [showViewEmailModal, setShowViewEmailCommModal] = useState(false);
  const [
    showDeleteSelectedDraftsModal,
    setShowDeleteSelectedDraftsModal,
  ] = useState(false);
  const [tabIndexSelected, setTabIndex] = useState(0);
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(true);
  const [confirmationTitle, setConfirmationTitle] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [draftId, setDraftId] = useState('');
  const [commId, setCommId] = useState(null);
  const deleteId = [];
  const [displayError, setDisplayError] = useState(false);
  const [, resetDeleteCommunication] = resetDeleteCommunicationAPI();
  const [, resetDeleteFilesData] = resetDeleteFiles();
  const [, resetGetDraftApi] = resetDraftApi();
  const [deleteFiles] = useDeleteFile();
  const [deleteCommunication] = useDeleteCommunicationAPI();
  const [deleteFlowTriggered, setDeleteFlow] = useState(false);
  const errorMessages = useLagoon('error-messages')();
  const [serverErrorMessage, setServerErrorMessage] = useState({
    header: '',
    message: '',
  });
  const router = useRouter();
  const modalName = constants.UHC_NOTIFY_MODAL_NAME;

  useEffect(() => {
    if (!checkInternalRole(userDSData)) router.push('/welcome');
    storage.local.set('IS_SIGN_IN_CLICKED', false);
    if (!document.getElementById('auth-script')) {
      const script = document.createElement('script');
      script.src = config('UHC_AUTH_SCRIPT');
      script.async = false;
      script.id = 'auth-script';
      document.head.appendChild(script);
    }

    if (!userInfo.data.firstName) {
      getUserInfo();
    }
    if (userInfo?.data?.firstName && callDsUserInfo) {
      setDsUserInfoCalled(true);
      getDSUserInfo({ userInfoData });
    }
  }, []);

  const getErrorMessage = messageKey => {
    const messageExist = errorMessages.find(
      message => message?.active && message?.key === messageKey
    );
    if (messageExist) {
      setDisplayError(true);
      const { header = '', message = '' } = messageExist || {};
      setServerErrorMessage({ header, message });
    }
  };

  useEffect(() => {
    if (userInfoData?.firstName && callDsUserInfo && !dsUserInfoCalled) {
      setDsUserInfoCalled(true);
      getDSUserInfo({ userInfoData });
    }
  }, [userInfo]);

  useEffect(() => {
    if (!userDSInfo?.loading && userDSData && userDSData?.uuid) {
      getCommunicationAPI({ allMessages: true });
    }
  }, [userDSInfo]);

  useEffect(() => {
    setDisplayError(false);
    if (!communicationAPI?.loading) {
      if (communicationAPI.error) {
        getErrorMessage('GET_COMMUNICATIONS_FAILED');
      } else {
        setDisplayError(false);
      }
      setisLoading(false);
    } else {
      setisLoading(true);
    }
  }, [communicationAPI]);
  useEffect(() => {
    if (
      !sentCommunication.loading &&
      commId !== null &&
      Object.keys(sentCommunication?.data || {})?.length
    ) {
      setShowViewEmailCommModal(true);
    }
    if (sentCommunication?.error) {
      setShowViewEmailCommModal(false);
      getErrorMessage('GET_VIEW_EMAIL_FAILED');
    }
  }, [sentCommunication]);

  useEffect(() => {
    if (draftApi?.error) {
      setShowCreateCommModal(false);
      getErrorMessage('GET_DRAFT_FAILED');
    }
    if (
      !draftApi.loading &&
      draftId &&
      !draftApi?.error &&
      Object.keys(draftApi?.data || {})?.length
    ) {
      setShowCreateCommModal(true);
    }
  }, [draftApi]);

  const onViewEmailClick = id => {
    setCommId(id);
    getSentCommunication({ id });
    setDisplayError(false);
    event('MODAL_VIEW', {
      event,
      modalName,
    });
  };

  const viewSentComm = id => {
    return (
      <Button
        id="viewCommunicationButton"
        variant="outline"
        fontSize="15px"
        size="$md"
        onClick={() => onViewEmailClick(id)}
        ariaLoadingLabel="Loading content"
        isLoading={commId === id && sentCommunication?.loading}
        key={id}
      >
        View Communication
      </Button>
    );
  };

  const onEditDraft = id => {
    setDraftId(id);
    resetGetDraftApi();
    setDisplayError(false);
    getDraftCommunication({ id });
  };

  const openDraftModal = id => {
    return (
      <Button
        id="editCommunicationButton"
        variant="tertiary"
        fontSize="15px"
        size="$md"
        onClick={() => onEditDraft(id)}
        ariaLoadingLabel="Loading content"
        isLoading={draftId === id && draftApi?.loading}
        key={id}
      >
        Edit Communication
      </Button>
    );
  };

  const createDraftData =
    draftMessages?.map((item, ind) => ({
      key: ind,
      draftDate: item.date,
      draftSubject: item.subject,
      id: item.id,
      actions: openDraftModal(item.id),
    })) || [];

  const createSentData =
    sentMessages?.map((item, ind) => ({
      key: ind,
      sentDate: item.date,
      sentSubject: item.subject,
      actions: viewSentComm(item.id),
    })) || [];

  const draftColumns = [
    {
      Header: 'Subject',
      accessor: 'draftSubject',
    },
    {
      Header: 'Release Date',
      accessor: 'draftDate',
    },
    {
      Header: 'Actions',
      accessor: 'actions',
    },
  ];

  const sentColumns = [
    {
      Header: 'Subject',
      accessor: 'sentSubject',
    },
    {
      Header: 'Release Date',
      accessor: 'sentDate',
    },
    {
      Header: 'Actions',
      accessor: 'actions',
    },
  ];

  const draftData = useMemo(() => [...createDraftData], [communicationAPI]);
  const draftDataTableProps = useDataTable({
    initialData: draftData,
    initialColumns: draftColumns,
    showPagination: true,
    showTopPagination: false,
    showSelection: true,
    noDataMessage: 'Your drafts folder is empty',
  });

  const sentData = useMemo(() => [...createSentData], [communicationAPI]);
  const sentDataTableProps = useDataTable({
    initialData: sentData,
    initialColumns: sentColumns,
    showPagination: true,
    showTopPagination: false,
    noDataMessage: 'Your drafts folder is empty',
  });

  const unCheckDraftTableRows = () => {
    draftDataTableProps.reactTableProps.tableActions.deleteRows(
      draftDataTableProps?.state?.selectedRowIds
    );
  };

  let hasFileIds = false;
  const selectedRows = draftDataTableProps?.state?.selectedRowIds || {};
  Object.keys(selectedRows || []).map(item => {
    if (draftMessages?.[item]?.fileIds?.length) hasFileIds = true;
    return deleteId.push(draftMessages?.[item]?.id);
  });

  useEffect(() => {
    if (Object.keys(selectedRows || {}).length) {
      setIsDeleteButtonDisabled(false);
    } else {
      setIsDeleteButtonDisabled(true);
    }
  }, [selectedRows]);

  useEffect(() => {
    draftDataTableProps.setData(createDraftData, true);
    sentDataTableProps.setData(createSentData, true);
  }, [communicationAPI, sentCommunication, draftApi]);
  useEffect(() => {
    if (tabIndexSelected === 1) {
      unCheckDraftTableRows();
      draftDataTableProps.setData(createDraftData, true);
    }
  }, [tabIndexSelected]);

  useEffect(() => {
    if (
      deleteFlowTriggered &&
      ((hasFileIds &&
        !deleteFiles?.loading &&
        Object.keys(deleteFiles?.data || {})?.length &&
        !deleteFiles?.error) ||
        (!hasFileIds &&
          !deleteCommunication.loading &&
          deleteCommunication.data.message &&
          !deleteCommunication.error))
    ) {
      unCheckDraftTableRows();
      setConfirmationTitle('Your draft has been deleted');
      setShowDeleteSelectedDraftsModal(false);
      setShowConfirmationModal(true);
      resetDeleteCommunication();
      resetDeleteFilesData();
      setDeleteFlow(false);
      setDisplayError(false);
      resetGetDraftApi();
    }

    if (deleteFiles?.error) {
      setShowDeleteSelectedDraftsModal(false);
      resetDeleteCommunication();
      resetDeleteFilesData();
      setDeleteFlow(false);
      resetGetDraftApi();
    }

    if (deleteCommunication?.error) {
      getErrorMessage('DELETE_DRAFT_FAILED');
      setShowDeleteSelectedDraftsModal(false);
      setShowConfirmationModal(false);
    }
  }, [deleteFiles, deleteCommunication]);

  const onViewEmailModalClose = () => {
    event('MODAL_CLOSE', {
      event,
      modalName,
    });
    setShowViewEmailCommModal(false);
  };

  const onCreateCommunicationClick = () => {
    setShowCreateCommModal(true);
    setDisplayError(false);
    unCheckDraftTableRows();
    draftDataTableProps.setData(createDraftData, true);
  };

  return (
    <PageLayout>
      {showConfirmationModal && (
        <ConfirmationModal
          title={confirmationTitle}
          isOpen={showConfirmationModal}
          isClosed={() => setShowConfirmationModal(false)}
        />
      )}
      {showCreateCommModal && (
        <CreateCommunicationModal
          showModal={showCreateCommModal}
          modalOnClose={setShowCreateCommModal}
          setConfirmationTitle={setConfirmationTitle}
          setShowConfirmationModal={setShowConfirmationModal}
        />
      )}
      {showDeleteSelectedDraftsModal && (
        <DeleteSelectedDraftsModal
          showModal={showDeleteSelectedDraftsModal}
          modalOnClose={setShowDeleteSelectedDraftsModal}
          ids={deleteId}
        />
      )}
      {showViewEmailModal && (
        <ViewEmailModal
          showModal={showViewEmailModal}
          modalOnClose={onViewEmailModalClose}
          emailTemplate={sentCommunication.data}
        />
      )}
      <Breadcrumbs />
      <PageHeading id="welcome-user">
        Welcome, {userInfo.data.firstName}
      </PageHeading>
      <AlertStyle>
        {displayError ? (
          <Alert title={serverErrorMessage?.header}>
            {serverErrorMessage?.message}
          </Alert>
        ) : null}
      </AlertStyle>
      <CreateCommunicationPageDesc />
      <LoadingOverlay
        loadingTitle="Please Wait"
        loadingMessage="while we retrieve your data."
        ariaLoadingLabel="Example loading aria label"
        isLoading={isLoading}
        width="40%"
      >
        <ButtonStyle>
          <Button
            size="$lg"
            fontSize="15px"
            onClick={() => onCreateCommunicationClick()}
            id="createCommunicationButton"
          >
            Create communication
          </Button>
          {tabIndexSelected === 0 && (
            <Button
              id="deleteSelectedDraftsButton"
              size="$lg"
              fontSize="15px"
              style={{ marginLeft: '40px' }}
              variant="destructive"
              isDisabled={isDeleteButtonDisabled}
              after={
                <IconMaterial
                  icon="delete"
                  size={20}
                  color={isDeleteButtonDisabled ? '$gray5' : '$primary1'}
                />
              }
              onClick={() => {
                setShowDeleteSelectedDraftsModal(true);
                setDeleteFlow(true);
                setDisplayError(false);
              }}
            >
              Delete selected drafts
            </Button>
          )}
        </ButtonStyle>
        <Tabs grow={false} active={tabIndexSelected} onTabChange={setTabIndex}>
          <Tabs.Tab
            label="Draft Communications"
            id="draftCommunicationsTab"
            style={{ fontSize: '15px' }}
          >
            <DataTable
              hideTitleHeader
              tableState={draftDataTableProps}
              id="draftDataTable"
              css={{
                'abyss-table-cell': { fontSize: '13px', fontWeight: 400 },
              }}
            />
          </Tabs.Tab>
          <Tabs.Tab
            label="Sent Communications"
            id="sentCommunicationsTab"
            style={{ fontSize: '15px' }}
          >
            <DataTable
              hideTitleHeader
              tableState={sentDataTableProps}
              id="sentDataTable"
              css={{
                'abyss-table-cell': { fontSize: '13px', fontWeight: 400 },
              }}
            />
          </Tabs.Tab>
        </Tabs>
      </LoadingOverlay>
    </PageLayout>
  );
};
