import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { styled } from '@abyss/web/tools/styled';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { saveAs } from 'file-saver';
import { event } from '@abyss/web/tools/event';
import { Button } from '@abyss/web/ui/Button';
import { DropdownMenu } from '@abyss/web/ui/DropdownMenu';
import { config } from '@abyss/ui/tools/config';
import { SubscribeModal } from '../../AllAPIs/SubscribeModal';
import { SubscribeToAPIModal } from '../../SubscribeToAPIModal';
import { useDSUserInfo } from '../../../../../hooks/useDSUserInfo';

const Wrapper = styled('div', {
  padding: '10px 0px 10px 10px',
});

const Flexed = styled('div', {
  display: 'flex',

  '@screen >= $xs': {
    flexDirection: 'column',
  },
  '@screen >= $md': {
    flexDirection: 'row',
  },
});

const StyledContent = styled('div', {
  display: 'block',
  padding: '5px',
  flexBasis: '55%',
  fontSize: '15px',
});

const StyledContentForButton = styled('div', {
  display: 'block',
  padding: '5px',
});

const StyledContentNoPadRight = styled('div', {
  display: 'block',
  padding: '5px 0px 5px 5px',
});

const StyledSwaggerDropdown = styled.div({
  fontStyle: 'italic',
  fontSize: '12px',
  lineHeight: '14px',
});

export const PageHeading = ({ info, currentApi, service }) => {
  const [isSubscribeModalOpen, setIsSubscribeModalOpen] = useState(false);
  const userInfo = useSelector(state => state.GET_USER_INFO.data);
  const dsUserInfo = useSelector(state => state.GET_DS_USER_INFO);
  const [showSubscribeAPIModal, setShowSubscribeAPIModal] = useState(false);
  const [, getDSUserInfo] = useDSUserInfo();

  useEffect(() => {
    if (userInfo.uuid && !dsUserInfo.data.status) {
      getDSUserInfo({ userInfo });
    }
  }, [userInfo]);

  const subscribeClicked = () => {
    const jobName = 'request an api subscription';

    event('SUBSCRIBE_EVENT', {
      event,
      jobName,
    });
    if (userInfo.firstName === '' || userInfo.error) {
      setIsSubscribeModalOpen(true);
    } else {
      setShowSubscribeAPIModal(true);
    }
  };
  const downloadProduction = () => {
    saveAs(
      `${config('DOCUMENTATION_API_PRODUCTION_URL')}${service}.json`,
      `${service} prod swagger.json`
    );

    const fileName = `download ${info.title} production swagger`;
    const fileURL = `${config(
      'DOCUMENTATION_API_PRODUCTION_URL'
    )}${service}.json`;
    const fileContext = 'swagger';

    event('DOWNLOAD_CLICK', {
      fileName,
      fileURL,
      fileContext,
    });
  };

  const downloadStage = () => {
    saveAs(
      `${config('DOCUMENTATION_API_STAGGING_URL')}${service}.json`,
      `${service} stage swagger.json`
    );

    const fileName = `download ${info.title} staging swagger`;
    const fileURL = `${config(
      'DOCUMENTATION_API_STAGGING_URL'
    )}${service}.json`;
    const fileContext = 'swagger';

    event('DOWNLOAD_CLICK', {
      fileName,
      fileURL,
      fileContext,
    });
  };

  const menuItems = [
    {
      title: 'Download Production',
      onClick: () => downloadProduction(),
      icon: <IconMaterial icon="download" variant="outlined" />,
    },
    {
      title: 'Download Staging',
      onClick: () => downloadStage(),
      icon: <IconMaterial icon="download" variant="outlined" />,
    },
    {
      title: (
        <StyledSwaggerDropdown>
          After downloading please use <br />
          the base url from the swagger <br />
          description for the actual API calls <br />
        </StyledSwaggerDropdown>
      ),
      onClick: () => {},
      icon: <IconMaterial icon="info" />,
    },
  ];

  return (
    <Wrapper>
      <SubscribeModal
        setIsSubscribeModalOpen={setIsSubscribeModalOpen}
        isSubscribeModalOpen={isSubscribeModalOpen}
      />
      <SubscribeToAPIModal
        showModal={showSubscribeAPIModal}
        modalOnClose={setShowSubscribeAPIModal}
      />
      <Flexed>
        <StyledContent>
          <Heading style={{ fontSize: '28px' }}>{info.title}</Heading>
          {currentApi.summary.includes('||') ? (
            <div>{currentApi.summary.split('||')[0]}</div>
          ) : (
            <div>{currentApi.summary}</div>
          )}
          <div>
            {currentApi.method} {currentApi.path}
          </div>
        </StyledContent>
        <StyledContentForButton space-self>
          <Button
            id="SubscribeToAPI"
            onClick={subscribeClicked}
            css={{
              'abyss-button-root': {
                width: '175.25px',
                height: '42px',
                fontSize: '15px',
              },
            }}
          >
            Subscribe to API
          </Button>
        </StyledContentForButton>
        <StyledContentNoPadRight space-self>
          <DropdownMenu
            label="Download Swagger"
            outline
            before={<IconMaterial icon="download" variant="outlined" />}
            after={<IconMaterial icon="keyboard_arrow_down" />}
            menuItems={menuItems}
            css={{
              'abyss-dropdown-menu-item': {
                height: 'auto',
                margin: '15px 0',
              },
            }}
          />
        </StyledContentNoPadRight>
      </Flexed>
    </Wrapper>
  );
};

PageHeading.propTypes = {
  info: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  currentApi: PropTypes.shape({
    path: PropTypes.string,
    summary: PropTypes.string,
    description: PropTypes.string,
    operationId: PropTypes.string,
    method: PropTypes.string,
  }),
  service: PropTypes.string,
};

PageHeading.defaultProps = {
  info: { title: '', description: '' },
  currentApi: {
    path: '',
    summary: '',
    description: '',
    operationId: '',
    method: '',
  },
  service: '',
};
