import React, { Fragment, useMemo, useState, useEffect } from 'react';
import { storage } from '@abyss/web/tools/storage';
import { config } from '@abyss/ui/tools/config';
import { styled } from '@abyss/web/tools/styled';
import { Button } from '@abyss/web/ui/Button';
import { useUserInfo } from 'src/hooks/useUserInfo';
import { LoadingOverlay } from '@abyss/web/ui/LoadingOverlay';
import { useDSUserInfo } from 'src/hooks/useDSUserInfo';
import { Tabs } from '@abyss/web/ui/Tabs';
import { useRouter } from '@abyss/ui/router/hooks/useRouter';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { useLagoon } from '@abyss/web/hooks/useLagoon';
import { Alert } from '@abyss/web/ui/Alert';
import { DataTable } from '@abyss/web/ui/DataTable';
import { event } from '@abyss/web/tools/event';
import { PageLayout } from '../../../../../common/PageLayout';
import { Breadcrumbs } from '../../../Breadcrumbs/Breadcrumbs';
import { AllCommunicationPageDesc } from './AllCommunicationPageDescription';
import { useGetCommunicationAPI } from '../../../../../hooks/useGetCommunicationAPI';
import { ViewEmailModal } from '../CreateCommunication/ViewEmailModal/ViewEmailModal';
import { useGetSentCommunicationApi } from '../../../../../hooks/useGetSentCommunicationApi';
import { constants } from '../../../../../common/Utils/Constants';

const PageHeading = styled('h1', {
  color: '#002677',
  fontSize: '41.04px',
  fontWeight: '600',
  lineHeight: '52px',
  marginBottom: '32px',
  '@screen < $sm': {
    fontSize: '25px',
  },
});

const AlertStyle = styled('div', {
  marginBottom: '35px',
});

export const AllCommunications = () => {
  const [userDSInfo, getDSUserInfo] = useDSUserInfo();
  const userDSData = userDSInfo?.data;
  const callDsUserInfo = !userDSData?.uuid && !userDSData?.status;
  const [dsUserInfoCalled, setDsUserInfoCalled] = useState(false);
  const [userInfo, getUserInfo] = useUserInfo();
  const userInfoData = userInfo.data;
  const [isLoading, setisLoading] = useState(false);
  const [communicationAPI, getCommunicationAPI] = useGetCommunicationAPI();
  const [commId, setCommId] = useState(null);
  const { recentMessages = [], archivedMessages = [] } =
    communicationAPI.data || {};
  const [
    sentCommunication,
    getSentCommunication,
  ] = useGetSentCommunicationApi();
  const [displayError, setDisplayError] = useState(false);
  const errorMessages = useLagoon('error-messages')();
  const [serverErrorMessage, setServerErrorMessage] = useState({
    header: '',
    message: '',
  });
  const [showViewEmailModal, setShowViewEmailCommModal] = useState(false);

  const getErrorMessage = messageKey => {
    const messageExist = errorMessages.find(
      message => message?.active && message?.key === messageKey
    );
    if (messageExist) {
      setDisplayError(true);
      const { header = '', message = '' } = messageExist || {};
      setServerErrorMessage({ header, message });
    }
  };

  const router = useRouter();
  const isUserActive = userDSData?.status && userDSData?.status === 'ACTIVE';
  const modalName = constants.UHC_NOTIFY_MODAL_NAME;

  useEffect(() => {
    if (!isUserActive) router.push('/welcome');
    storage.local.set('IS_SIGN_IN_CLICKED', false);
    if (!document.getElementById('auth-script')) {
      const script = document.createElement('script');
      script.src = config('UHC_AUTH_SCRIPT');
      script.async = false;
      script.id = 'auth-script';
      document.head.appendChild(script);
    }

    if (!userInfo.data.firstName) {
      getUserInfo();
    }
    if (userInfo?.data?.firstName && callDsUserInfo) {
      setDsUserInfoCalled(true);
      getDSUserInfo({ userInfoData });
    }
  }, []);
  useEffect(() => {
    if (userInfoData?.firstName && callDsUserInfo && !dsUserInfoCalled) {
      setDsUserInfoCalled(true);
      getDSUserInfo({ userInfoData });
    }
  }, [userInfo]);

  useEffect(() => {
    //  if ((!userDSInfo?.loading && userDSData && userDSData?.uuid)) {
    getCommunicationAPI({ allMessages: true });
    // }
  }, []);
  useEffect(() => {
    setDisplayError(false);
    if (!communicationAPI?.loading) {
      if (communicationAPI.error) {
        getErrorMessage('GET_COMMUNICATIONS_FAILED');
      } else {
        setDisplayError(false);
      }
      setisLoading(false);
    } else {
      setisLoading(true);
    }
  }, [communicationAPI]);

  useEffect(() => {
    if (
      !sentCommunication.loading &&
      commId !== null &&
      Object.keys(sentCommunication?.data || {})?.length
    ) {
      setShowViewEmailCommModal(true);
    }
    if (sentCommunication?.error) {
      setShowViewEmailCommModal(false);
      getErrorMessage('GET_VIEW_EMAIL_FAILED');
    }
  }, [sentCommunication]);

  const onViewEmailClick = (id, interName, interContext) => {
    setCommId(id);
    getSentCommunication({ id });
    setDisplayError(false);
    const interactionName = interName;
    const interactionValue = 'View Communication';
    const interactionContext = interContext;
    event('INTERACTION_EVENT', {
      interactionName,
      interactionValue,
      interactionContext,
    });
    event('MODAL_VIEW', {
      event,
      modalName,
    });
  };

  const viewComm = (id, interName, interContext) => {
    return (
      <Button
        id="viewCommButton"
        variant="outline"
        size="$md"
        onClick={() => onViewEmailClick(id, interName, interContext)}
        key={id}
        fontSize="15px"
        ariaLoadingLabel="Loading content"
        isLoading={commId === id && sentCommunication?.loading}
      >
        View Communication
      </Button>
    );
  };

  const createRecentCommData =
    recentMessages?.map((item, ind) => ({
      key: ind,
      recentDate: item.date,
      recentSubject: item.subject,
      id: item.id,
      actions: viewComm(item.id, item.subject, 'Recent Communications'),
    })) || [];
  const createArchiveCommData =
    archivedMessages?.map((item, ind) => ({
      key: ind,
      archiveDate: item.date,
      archiveSubject: item.subject,
      actions: viewComm(item.id, item.subject, 'Archived Communications'),
    })) || [];

  const recentColumns = [
    {
      Header: 'Subject',
      accessor: 'recentSubject',
    },
    {
      Header: 'Release Date',
      accessor: 'recentDate',
    },
    {
      Header: 'Actions',
      accessor: 'actions',
    },
  ];

  const archiveColumns = [
    {
      Header: 'Subject',
      accessor: 'archiveSubject',
    },
    {
      Header: 'Release Date',
      accessor: 'archiveDate',
    },
    {
      Header: 'Actions',
      accessor: 'actions',
    },
  ];

  const recentData = useMemo(() => [...createRecentCommData], [
    communicationAPI,
  ]);
  const recentDataTableProps = useDataTable({
    initialData: recentData,
    initialColumns: recentColumns,
    showPagination: true,
    showTopPagination: false,
    noDataMessage: 'Your recent communication folder is empty',
  });

  const archiveData = useMemo(() => [...createArchiveCommData], [
    communicationAPI,
  ]);
  const archiveDataTableProps = useDataTable({
    initialData: archiveData,
    initialColumns: archiveColumns,
    showPagination: true,
    showTopPagination: false,
    noDataMessage: 'Your Archives folder is empty',
  });
  const onViewEmailModalClose = () => {
    event('MODAL_CLOSE', {
      event,
      modalName,
    });
    setShowViewEmailCommModal(false);
  };

  useEffect(() => {
    recentDataTableProps.setData(createRecentCommData, true);
    archiveDataTableProps.setData(createArchiveCommData, true);
  }, [communicationAPI, sentCommunication]);

  const handleTabChange = tab => {
    const interactionName = 'All Communications';
    const interactionValueList = [
      'Recent Communication',
      'Archived Communications',
    ];
    const interactionValue = interactionValueList[tab];
    const interactionContext = 'tab clicks';
    event('INTERACTION_EVENT', {
      interactionName,
      interactionValue,
      interactionContext,
    });
  };

  return (
    <Fragment>
      <PageLayout>
        {showViewEmailModal && (
          <ViewEmailModal
            showModal={showViewEmailModal}
            modalOnClose={onViewEmailModalClose}
            emailTemplate={sentCommunication.data}
          />
        )}
        <Breadcrumbs />
        <PageHeading id="welcome-user">
          Welcome {userInfo.data.firstName}
        </PageHeading>
        <AlertStyle>
          {displayError ? (
            <Alert title={serverErrorMessage?.header}>
              {serverErrorMessage?.message}
            </Alert>
          ) : null}
        </AlertStyle>

        <AllCommunicationPageDesc />

        <LoadingOverlay
          loadingTitle="Please Wait"
          loadingMessage="while we retrieve your data."
          ariaLoadingLabel="Example loading aria label"
          isLoading={isLoading}
          width="40%"
        >
          <Tabs grow={false} onTabChange={handleTabChange}>
            <Tabs.Tab
              label="Recent Communications"
              id="recentCommunicationsTab"
              style={{ marginTop: '35px', fontSize: '15px' }}
            >
              <DataTable
                hideTitleHeader
                tableState={recentDataTableProps}
                id="recentDataTable"
                css={{
                  'abyss-table-cell': { fontSize: '13px', fontWeight: 400 },
                }}
              />
            </Tabs.Tab>
            <Tabs.Tab
              label="Archived Communications"
              id="archivedCommunicationsTab"
              style={{ marginTop: '35px', fontSize: '15px' }}
            >
              <DataTable
                hideTitleHeader
                tableState={archiveDataTableProps}
                id="archiveDataTable"
                css={{
                  'abyss-table-cell': { fontSize: '13px', fontWeight: 400 },
                }}
              />
            </Tabs.Tab>
          </Tabs>
        </LoadingOverlay>
      </PageLayout>
    </Fragment>
  );
};
