import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { config } from '@abyss/ui/tools/config';
import { event as newRelicEvent } from '@abyss/web/tools/event';

const useAction = actionFn => {
  const dispatch = useDispatch();
  return useCallback(
    (...args) => {
      return dispatch(actionFn(...args));
    },
    [dispatch]
  );
};

export const ACTION_TYPE = 'GET_DS_USER_INFO';

export const useDSUserInfo = () => {
  const result = useSelector(state => state[ACTION_TYPE]);

  const action = useAction(args => {
    return dispatch => {
      dispatch({ type: `${ACTION_TYPE}_CALLED` });
      fetch(`${config('DS_PROXY_URL')}${config('DS_USER_INFO_URL')}`, {
        withCredentials: 'include',
        method: 'post',
      })
        .then(res => {
          newRelicEvent('NEWRELIC_API_TRANSACTION', {
            code: res.status,
            message: res.statusText,
            endpoint: config('DS_USER_INFO_URL'),
            userInfo: args.userInfo,
            method: 'POST',
          });
          return res.json();
        })
        .then(res => {
          dispatch({
            type: `${ACTION_TYPE}_COMPLETED`,
            payload: res,
          });
        })
        .catch(error => {
          dispatch({
            type: `${ACTION_TYPE}_ERROR`,
            payload: error,
          });
        });
    };
  });

  return [result, action];
};
